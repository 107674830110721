import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const SlideInVert = ({ children, slideTop = true, delay = 0, damp = 8, mass = 0.45, stiffness = 40 }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: 0.9 })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      //controls.start("hidden")
    }
  }, [controls, inView])

  const slideVars = () => {
    let y = slideTop ? "-100vh" : "100vh"
    const variants = {
      visible: {
        y: 0,
        transition: { delay: delay, duration: 5, type: "spring", stiffness: stiffness, mass: mass, damping: damp },
      },
      hidden: {
        y: y,
      },
    }
    return variants
  }

  return (
    <div ref={ref}>
      <motion.div animate={controls} initial="hidden" variants={slideVars()}>
        {children}
      </motion.div>
    </div>
  )
}

export default SlideInVert
