import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const FadeIn = ({ children, thresh = 0.45, delay = 0, animate = true }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: thresh })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    } else {
      // controls.start("hidden")
    }
  }, [controls, inView])

  const fadeVars = () => {
    const variants = {
      visible: {
        opacity: 1,
        transition: {
          delay: delay,
          duration: 0.8,
        },
      },
      hidden: {
        opacity: 0,
      },
    }
    return variants
  }

  if (!animate) {
    return <>{children}</>
  }

  return (
    <div ref={ref}>
      <motion.div animate={controls} initial="hidden" variants={fadeVars()}>
        {children}
      </motion.div>
    </div>
  )
}

export default FadeIn
