import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"
import HomeIcon from "@material-ui/icons/Home"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const useStyles = makeStyles(theme => ({
  root: {
    "& > svg": {
      top: "3px",
      position: "relative",
    },
  },
  LinkColour: {
    color: props => (props.light ? "black" : "white"),
  },
}))

const NavWrapper = styled(Grid)`
  position: absolute;
  top: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0);
  z-index: 9999;
`

const NavList = styled(List)`
  display: flex;
  flexdirection: row;
  padding: 0;
`

const NavListItem = styled(ListItem)`
  margin-left: 2px;
`

const NavLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
`

export default function Navbar(props) {
  const classes = useStyles(props)
  let linkColour = props.light ? "black" : "white"

  return (
    <NavWrapper
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
    >
      <NavList>
        <NavListItem>
          <NavLink to="/" title="Home">
            <div className={classes.root}>
              <HomeIcon style={{ color: linkColour }} />
            </div>
          </NavLink>
        </NavListItem>
        <NavListItem>
          <NavLink to="/blog" title="Blog">
            <div className={classes.root}>
              <MenuBookIcon style={{ color: linkColour }} />
            </div>
          </NavLink>
        </NavListItem>
        <NavListItem>
          <a
            href="https://www.linkedin.com/in/steve-newman-61561a37/"
            target="_blank"
            title="LinkedIn"
          >
            <div className={classes.root}>
              <LinkedInIcon style={{ color: linkColour }} />
            </div>
          </a>
        </NavListItem>
      </NavList>
    </NavWrapper>
  )
}
