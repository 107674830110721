import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { withStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import SlideInVert from "../animations/slideInVert"
import FadeIn from "../animations/fadeIn"

const HeroSplash = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  opacity: 1 !important;
  background-size: cover;
  background: rgba(0, 0, 0, 0.2);
  background-size: cover;
  overflow: hidden;
`

const HeroProfileImg = styled(Img)`
  max-width: 150px;
  height: auto;
  margin: 0 auto 20px;
`

const HeroContent = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  font-family: "Open Sans";
`

const HeroHeading = withStyles(theme => ({
  root: {
    fontWeight: 400,
    lineHeight: 0.9,
  },
}))(Typography)

const HeroSubHeading = styled.span`
  display: inline-block;
`

const Hero = ({ className, heading, subHeading }) => (
  <StaticQuery
    query={graphql`
      query {
        bg: file(relativePath: { eq: "steve_newman_portfolio.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        profile: file(relativePath: { eq: "profile_img.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 154) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const bg = data.bg.childImageSharp.fluid
      const profile = data.profile.childImageSharp.fluid
      return (
        <div className={className + "-wrapper"}>
          <HeroSplash className={className + " valign-wrapper"} fluid={bg}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              css={{ height: "100%" }}
              p={2}
            >
              <Box>
                <HeroContent>
                  <FadeIn delay={2}>
                    <HeroProfileImg fluid={profile} />
                  </FadeIn>
                  <SlideInVert slideTop={false} delay={1}>
                    <HeroHeading variant="h2" component="h1">
                      {heading}
                    </HeroHeading>
                    <HeroSubHeading>{subHeading}</HeroSubHeading>
                  </SlideInVert>
                </HeroContent>
              </Box>
            </Box>
          </HeroSplash>
        </div>
      )
    }}
  />
)

export default Hero
